import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import { Data } from "../Data";

function ContactDetails() {
  const { about, links } = useContext(Data);

  // Filter active links from the links collection
  const activeLinks = links.filter((link) => link.isOn);

  return (
    <div className="contact-details-container">
      {/* Address, email, and phone */}
      <Row className="justify-content-center mb-2">
        {about.address && (
          <Col xs="auto" className="contact-item">
            {about.address}
          </Col>
        )}
        {about.email && (
          <Col xs="auto" className="contact-item">
            <p  className="contact-link">
              {about.email}
            </p>
          </Col>
        )}
        {about.phone && (
          <Col xs="auto" className="contact-item">
            <p className="contact-link">
              {about.phone}
            </p>
          </Col>
        )}
      </Row>

      {/* Links */}
      {activeLinks.length > 0 && (
        <Row className="justify-content-center">
          {activeLinks.map(({ name, link }) => (
            <Col xs="auto" key={name} className="contact-item">
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                {name}
              </a>
            </Col>
          ))}
        </Row>
      )}

      <hr />
    </div>
  );
}

export default ContactDetails;

import React from "react";
import { Row, Col, Button } from "reactstrap";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "October 2024"
};

function Card({
  title,
  roles,
  company,
  startDate,
  endDate,
  brief,
  logoUrl,
  status, // New prop
  onClick,
}) {
  return (
    <div className="portfolio-card">
      <Row>
        <h2 className="portfolio-title" onClick={onClick} style={{ cursor: "pointer" }}>
          {title}
        </h2>
      </Row>
      <br />
      <Row xs="1" sm="2">
        <Col sm="12" md="6">
          {/* Conditional rendering for roles */}
          {roles && roles.length > 0 && <p className="role">{roles.join(", ")}</p>}
          {/* Render company and dates on the same line */}
          {(company || startDate || endDate) && (
            <p className="company-dates">
              {company && <span className="company">{company}</span>}
              {(startDate || endDate) && (
                <span className="dates">
                  {" | "}
                  {startDate && formatDate(startDate) + "-"}  {endDate ?  formatDate(endDate) : "Present"}
                </span>
              )}
            </p>
          )}
          {/* Display project statuses */}
          {status && status.length > 0 && (
            <div className="status-container">
              {status.map((stat, idx) => (
                <span key={idx} className="status-badge">
                  {stat}
                </span>
              ))}
            </div>
          )}
          <br />
          {brief && <p className="description">{brief}</p>}
          <br />
          <Button onClick={onClick}>See Details</Button>
        </Col>
        <Col sm="12" md="6" className="thumbnail-container">
          {logoUrl && (
            <div
              className="thumbnail portfolio-card-image"
              style={{
                backgroundImage: `url(${logoUrl})`,
                cursor: "pointer",
              }}
              aria-label={`${title} thumbnail`}
              onClick={onClick}
            ></div>
          )}
        </Col>
      </Row>
      <br />
      <hr />
    </div>
  );
}

export default Card;

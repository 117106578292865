import React from "react";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings

  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "August 2024"
};

function EducationCard({
  degree,
  department,
  startDate,
  endDate,
  university,
  location,
  description,
  isPresent,
}) {
  // Check if description is non-empty and not just <p><br/></p>
  const hasValidDescription =
    description && description.trim() !== "" && description !== "<p><br></p>";

  return (
    <div className="education-card">
      {/* Display degree and department in the same row */}
      <p className="education-title">
        <strong className="strong">{degree}</strong> &nbsp;|&nbsp; {department}
      </p>
      <p className="education-date">
        {formatDate(startDate)} - {isPresent ? "Present" : formatDate(endDate)} |  {university}, {location}
      </p>

      
      {/* Render description if it is valid */}
      {hasValidDescription && (
        <div
          className="education-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
    </div>
  );
}

export default EducationCard;

import React from "react";

function Achievements({ achievements }) {
  // Sort achievements by date (most recent first)
  const sortedAchievements = achievements?.sort((a, b) => new Date(b.date) - new Date(a.date));

  return sortedAchievements?.length > 0 ? (
    <>
      <h3>Achievements</h3>
      <ul>
        {sortedAchievements.map((achievement, index) => (
          <li key={index}>
            <strong>{achievement.name}</strong> at{" "}
            {achievement.issuingBody ? (
              <a href={achievement.issuingBodyURL} target="_blank" rel="noopener noreferrer">
                {achievement.issuingBody}
              </a>
            ) : (
              achievement.issuingBody
            )},{" "}
            {new Date(achievement.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
            {achievement.description && (
              <div className="achievement-description" dangerouslySetInnerHTML={{ __html: achievement.description }}></div>
            )}
          </li>
        ))}
      </ul>
    </>
  ) : null;
}

export default Achievements;

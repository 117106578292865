import React from "react";
import { Col } from "reactstrap";

function Skills({ skills }) {
  const sortedSkills = skills
    .filter(skill => skill.isOn) // Display only active skill groups
    .sort((b, a) => a.priority - b.priority);

  return sortedSkills.length > 0 ? (
    <>
      {sortedSkills.map((skillGroup, index) => (
        <Col key={index} md="4" sm="6" xs="12" className="skill-group-column">
          <h4>{skillGroup.title}</h4>
          <div className="skills-container">
            {skillGroup.keywords.map((keyword, idx) => (
              <span key={idx} className="skill-badge">
                {keyword}
              </span>
            ))}
          </div>
        </Col>
      ))}
    </>
  ) : null;
}

export default Skills;

import React, { useContext } from "react";
import { SECTIONS } from "../constants";
import ExperienceCard from "../components/ExperienceCard";
import EducationCard from "../components/EducationCard";
import { Helmet } from "react-helmet";
import { Data } from "../Data";
import Loading from "./Loading";
import Languages from "../components/Languages";
import Skills from "../components/Skills";
import Certifications from "../components/Certifications";
import Achievements from "../components/Achievements";
import Awards from "../components/Awards";
import Publications from "../components/Publications";
import VolunteerWork from "../components/VolunteerWork";
import { Row } from "reactstrap";
import ContactDetails from "../components/ContactDetails";

function About() {
  const {
    about,
    education,
    experience,
    certifications,
    achievements,
    publications,
    volunteerWork,
    languages,
    awards,
    skills,
    loading,
  } = useContext(Data);

  if (loading) {
    return <Loading />;
  }

  const hasValidContent = (htmlString) => {
    if (!htmlString) return false; // Check if htmlString is undefined or empty
    const cleanedString = htmlString.replace(/<p><br><\/p>/g, "").trim();
    return cleanedString.length > 0;
  };

  const sortedExperience = experience?.sort((a, b) => {
    const aCurrent = a.isPresent || a.isPresent2 || a.isPresent3;
    const bCurrent = b.isPresent || b.isPresent2 || b.isPresent3;

    // Current jobs come first
    if (aCurrent && !bCurrent) return -1;
    if (!aCurrent && bCurrent) return 1;

    // Otherwise, sort by most recent start date
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return dateB - dateA;
  });

  const sortedEducation = education?.sort((a, b) => {
    if (a.isPresent && !b.isPresent) return -1;
    if (!a.isPresent && b.isPresent) return 1;
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return dateB - dateA;
  });

  return (
    <div className="about-container">
      <Helmet>
        <title>{`${about.name || "About"} | About`}</title>
        <meta
          name="description"
          content={`Learn more about ${
            about.name || "this individual"
          } from their Portfolio App.`}
        />
        <meta
          property="og:title"
          content={`${about.name || "About"} | About`}
        />
        <meta
          property="og:description"
          content={`${about.description || ""}`}
        />
        <meta
          property="og:url"
          content={`${about.domain || ""}/${SECTIONS.about}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <ContactDetails />

      {about.about && hasValidContent(about.about) && (
        <div dangerouslySetInnerHTML={{ __html: about.about }}></div>
      )}

      {sortedExperience?.length > 0 && (
        <>
          <h3>Experience</h3>
          {sortedExperience.map((exp, index) => (
            <ExperienceCard key={index} {...exp} />
          ))}
        </>
      )}

      <Achievements achievements={achievements} />

      {/* Dynamic row for languages and skills */}
      <Row className="languages-skills-container">
        <Languages languages={languages} />
        <Skills skills={skills} />
      </Row>

      {sortedEducation?.length > 0 && (
        <>
          <h3>Education</h3>
          {sortedEducation.map((edu, index) => (
            <EducationCard key={index} {...edu} />
          ))}
        </>
      )}

      <Certifications certifications={certifications} />
      <Publications publications={publications} />
      <VolunteerWork volunteerWork={volunteerWork} />
      <Awards awards={awards} />

      {hasValidContent(about.passion) && (
        <>
          <h3>Passion</h3>
          <div dangerouslySetInnerHTML={{ __html: about.passion }}></div>
        </>
      )}

      {hasValidContent(about.hobbies) && (
        <>
          <h3>Hobbies</h3>
          <div dangerouslySetInnerHTML={{ __html: about.hobbies }}></div>
        </>
      )}
    </div>
  );
}

export default About;

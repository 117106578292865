import React from "react";

// Helper function to format dates as "Month Year"
const formatDate = (dateString) => {
  if (!dateString) return ""; // Handle empty date strings
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options); // e.g., "October 2024"
};

function ExperienceCard({
  role,
  company,
  startDate,
  endDate,
  location,
  description,
  type,
  isPresent,
  role2,
  startDate2,
  endDate2,
  location2,
  description2,
  isPresent2,
  role3,
  startDate3,
  endDate3,
  location3,
  description3,
  isPresent3,
}) {
  const hasValidDescription = (desc) =>
    desc && desc.trim() !== "" && desc !== "<p><br></p>";

  return (
    <div className="experience-card">
      <span className="company">{company}</span>  
        {/* Third Entry */}
        {role3 && (
        <>
          <p className="experience-title">
            <strong className="strong">{role3}</strong> 
          </p>
          <p className="experience-date">
            {formatDate(startDate3)} - {isPresent3 ? "Present" : formatDate(endDate3)} | {location3}
          </p>
          {hasValidDescription(description3) && (
            <div
              className="experience-description"
              dangerouslySetInnerHTML={{ __html: description3 }}
            ></div>
          )}
        </>
      )}

         {/* Second Entry */}
         {role2 && (
        <>
          <p className="experience-title">
            <strong className="strong">{role2}</strong> 
          </p>
          <p className="experience-date">
            {formatDate(startDate2)} - {isPresent2 ? "Present" : formatDate(endDate2)} | {location2}
          </p>
          {hasValidDescription(description2) && (
            <div
              className="experience-description"
              dangerouslySetInnerHTML={{ __html: description2 }}
            ></div>
          )}
        </>
      )}
     
      {/* First Entry */}
      {role && (
        <>
          <p className="experience-title">
            <strong className="strong">{role}</strong> 
          </p>
          <p className="experience-date">
            {formatDate(startDate)} - {isPresent ? "Present" : formatDate(endDate)} | {location}{" "}
            {type && <span>| {type}</span>}
          </p>
          {hasValidDescription(description) && (
            <div
              className="experience-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
        </>
      )}

   
    </div>
  );
}

export default ExperienceCard;
